<script lang="ts" setup>
const { t } = useT();
const { data } = useAppInitData();
const isGuest = useIsGuest();
const { handleSupportButtonClick } = useSupportChat();

const hasDeposits = computed(() => data.value?.hasDeposits);
const isShowAffiliate = computed(() => !isGuest.value && data.value?.isAffiliate);

const filterLinksCallback = (slug: string) => {
	const rules = [
		{ slugs: ["alternative-method-of-entry"], condition: () => isGuest.value },
		{ slugs: ["affiliate-agreement", "partners-rules"], condition: () => !isShowAffiliate.value },
		{ slugs: ["promotional-games-rules", "refund-policy"], condition: () => !hasDeposits.value }
	];

	return !rules.some((rule) => rule.slugs.includes(slug) && rule.condition());
};

const termsLinks = computed(() =>
	data.value?.footer?.menu
		?.find((item) => item?.title === "second_column")
		?.items?.filter((link) => link?.slug && filterLinksCallback(link.slug))
);

const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "footer",
		button_name: "support"
	});
	handleSupportButtonClick();
};
</script>
<template>
	<footer class="footer py-5 px-4">
		<div class="wrapper">
			<div class="terms-list d-flex flex-column flex-lg-row gap-4 gap-xl-6">
				<NuxtLink
					v-for="(link, index) in termsLinks"
					:key="link.slug"
					:data-tid="`footer-terms-${index}`"
					:to="`/page/${link.slug}`"
					class="color-neutral"
				>
					<AText type="small" :modifiers="['link', 'underline']" class="color-neutral">{{ link.title }}</AText>
				</NuxtLink>
			</div>

			<ADivider class="my-5" :bg-color="'var(--gray-600)'" />

			<div class="info d-flex flex-column flex-lg-row gap-5 gap-lg-6">
				<div class="support d-flex flex-column gap-4">
					<AText
						as="div"
						type="small"
						:modifiers="['link', 'underline']"
						data-tid="footer-support"
						@click="handleClickOpenSupport"
					>
						{{ t("Online support") }}
					</AText>
					<AText
						as="a"
						type="small"
						data-tid="footer-support-email"
						:modifiers="['link', 'underline']"
						href="mailto:support@taofortune.com"
					>
						support@taofortune.com
					</AText>
					<NuxtLink
						to="https://www.trustpilot.com/review/taofortune.com"
						data-tid="footer-trustpilot"
						external
						target="_blank"
					>
						<NuxtImg
							src="/nuxt-img/footer/trustpilot.png"
							class="trustpilot"
							alt="trustpilot"
							format="webp"
							width="240"
							height="110"
						/>
					</NuxtLink>
					<NuxtIcon name="40/18+" class="age-icon" filled />
				</div>
				<div class="about color-gray" data-tid="footer-about">
					<AText type="small" as="p">
						{{
							t(
								"NO PURCHASE IS NECESSARY to enter free game promotion (“promotional games”). PROMOTIONAL GAMES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see Terms of Use."
							)
						}}
					</AText>
					<AText type="small" as="p">
						{{
							t(
								"TaoFortune Social Gaming Platform is a play-for-fun website intended for amusement purposes only. TaoFortune Social Gaming Platform does not offer “real-money gambling” or opportunity to win real money based on a gameplay."
							)
						}}
					</AText>
					<AText type="small" as="p">
						{{
							t(
								"TaoFortune Social Gaming Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry."
							)
						}}
					</AText>
				</div>
			</div>

			<ADivider class="mt-5 mb-4" :bg-color="'var(--gray-600)'" />

			<div class="copyright d-flex flex-column flex-lg-row justify-content-between align-items-center gap-3">
				<NuxtLink
					class="d-flex"
					to="https://www.facebook.com/profile.php?id=100088085575613"
					data-tid="footer-social-facebook"
					external
					target="_blank"
				>
					<NuxtImg
						src="/nuxt-img/footer/fb.png"
						class="facebook"
						alt="facebook"
						format="webp"
						width="227"
						height="64"
					/>
				</NuxtLink>
				<AText class="color-neutral" type="small" data-tid="footer-copyright">
					<i18n-t keypath="{key1} {key2} TaoFortune Social Gaming Platform">
						<template #key1> &copy; </template>
						<template #key2> {{ new Date().getFullYear() }} </template>
					</i18n-t>
				</AText>
			</div>
		</div>
	</footer>
</template>
<style lang="scss" scoped>
.footer {
	@include media-breakpoint-up(lg) {
		margin-left: var(--o-sidebar-width);
	}
}

.wrapper {
	max-width: 1022px;
	width: 100%;
	margin: 0 auto;
}

.terms-list {
	a {
		&:hover {
			text-decoration: none;
		}
	}
}

.support {
	min-width: 230px;
	position: relative;

	.link {
		display: flex;
		align-items: center;
		gap: gutter(1);

		.nuxt-icon {
			font-size: 24px;
		}
	}
}

.age-icon {
	font-size: 40px;
}

.trustpilot {
	width: 120px;
	height: 55px;
}

.facebook {
	width: 113px;
	height: 32px;
}
</style>
